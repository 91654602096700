import { useEffect, useState } from "react"
import Link from "next/link"
import { StaticImageData } from "next/image"
import { useRouter } from "next/router"
import { Login as LoginPage } from "@parallel-domain/pd-theme"
import { signIn, SignInResponse } from "next-auth/react"
import axios from "axios"
import { toast } from "react-toastify"
import parse from "html-react-parser"

// import app components
import useCustomSession from "features/authentication/hooks/useSession"
import BackgroundImage from "components/backgroundImage"
import image from "assets/images/nightRainCrosswalk.png"

const Login = () => {
  const [loading, setLoading] = useState<"email" | "sso" | "code" | "redirect" | "">("")
  const [callback, setCallback] = useState("/datasets")

  const router = useRouter()

  const { status } = useCustomSession()

  /**
   * Update callback url if parameter is provided
   */
  useEffect(() => {
    if (router?.query?.callback) {
      setCallback(router.query.callback as string)
    }
  }, [router?.query?.callback])

  /**
   * Redirect user in case authentication status changes
   */
  useEffect(() => {
    if (status === "authenticated") {
      router.push(callback)
    }
  }, [status])

  // Sign in user on redirect
  useEffect(() => {
    const signInWithSSO = async () => {
      setLoading("code")

      const { code, state: callback } = router.query

      if (callback) {
        setCallback(callback as string)
      }

      // Remove code GET parameter from URL
      router.replace(router.pathname, undefined, { shallow: true })

      const response: SignInResponse | undefined = await signIn("sso", { code, redirect: false })

      if (response?.ok) {
        setLoading("redirect")
      } else {
        toast.error(<>{parse(response?.error || "Something went wrong")}</>)
        setLoading("")
      }
    }

    if (router?.query?.code) {
      signInWithSSO()
    }
  }, [router])

  const handleSubmitSSO = async (formData: { email: string }) => {
    setLoading("sso")

    try {
      const response: { data: { url: string } } = await axios.post("/api/auth/sso", formData)

      let url = response.data.url

      if (router?.query?.callback) {
        const delimiter = url.includes("?") ? "&" : "?"
        url = `${url}${delimiter}state=${router.query.callback}`
      }

      // Redirect to sso provider URL
      window.location.replace(url)
    } catch (e) {
      const error = e as { response: { data: { message: string } } }
      toast.error(<>{parse(error.response.data.message)}</>)
      setLoading("")
    }
  }

  const handleSubmitEmailPassword = async (formData: { email: string; password: string }) => {
    setLoading("email")

    const response: SignInResponse | undefined = await signIn("credentials", { ...formData, redirect: false })

    if (!response?.ok) {
      toast.error(<>{parse(response?.error || "Something went wrong")}</>)
      setLoading("")
    }
  }

  return (
    <LoginPage
      quote="Their platform provided us with a diverse dataset with edge cases and accurate annotations that would not have been possible with our real world data operations."
      authorOrRole="Adrien Gaidon"
      description="Senior Machine Learning Manager, Toyota Research Institute"
      backgroundImage={<BackgroundImage image={image as StaticImageData} quality={100} priority />}
      message="Nice to see  you again!"
      loading={loading}
      linkComponent={Link}
      loginLink="/auth/login"
      registerLink="/auth/register"
      resetPasswordLink="/auth/reset-password"
      onSubmitEmailPassword={handleSubmitEmailPassword}
      onSubmitSSO={handleSubmitSSO}
    />
  )
}

export default Login
